import { EmptyPage } from "@components/common/empty-page/empty-page";
import {
  fetchingStatusCaptureTreeForMainRevisionSelector,
  hasFetchedCaptureTreeForMainRevisionSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { uploadedDataSelector } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-selectors";
import { useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { useMemo } from "react";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { UploadedDataTable } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-table";
import { PrepareDataButton } from "@pages/project-details/project-data-management/prepare-data-button";
import { sphereColors } from "@styles/common-colors";
import { Box } from "@mui/material";
import { PROJECT_DATA_MANAGEMENT_LABELS } from "@pages/project-details/project-data-management/data-management-constants";
import { selectProjectLoadingState } from "@faro-lotv/project-source";

/** Renders the uploaded data section of the data management page */
export function UploadedData(): JSX.Element {
  const uploadedData = useAppSelector(uploadedDataSelector);

  const fetchingStatus = useAppSelector(
    fetchingStatusCaptureTreeForMainRevisionSelector
  );
  const hasFetchedCaptureTree = useAppSelector(
    hasFetchedCaptureTreeForMainRevisionSelector
  );
  const fetchingStatusIElements = useAppSelector(selectProjectLoadingState);

  const isFetchingForTheFirstTime = useMemo(() => {
    return (
      !hasFetchedCaptureTree &&
      (fetchingStatus === FetchingStatus.pending ||
        fetchingStatusIElements === "loading")
    );
  }, [fetchingStatus, fetchingStatusIElements, hasFetchedCaptureTree]);

  const shouldShowEmptyPage = useMemo(() => {
    return uploadedData.length === 0 && !isFetchingForTheFirstTime;
  }, [uploadedData.length, isFetchingForTheFirstTime]);

  const hasFailedToFetchCaptureTree = useMemo(() => {
    return (
      fetchingStatus === FetchingStatus.rejected ||
      fetchingStatusIElements === "failed"
    );
  }, [fetchingStatus, fetchingStatusIElements]);

  return (
    <Box
      data-testid="uploaded-data-content"
      sx={{
        marginTop: "14px",
        border: `1px solid ${sphereColors.gray200}`,
        borderRadius: 0,
      }}
    >
      {shouldShowEmptyPage ? (
        <EmptyPage
          title={hasFailedToFetchCaptureTree ? "Error" : "No uploaded data"}
          subtitle={
            hasFailedToFetchCaptureTree
              ? "Failed to fetch the uploaded data. Please reload the page and try again."
              : "No uploaded data to show for this project."
          }
          icon={hasFailedToFetchCaptureTree ? FailedIcon : NoDataIcon}
        />
      ) : (
        <Box
          sx={{
            background: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: `1px solid ${sphereColors.gray200}`,
              paddingX: "30px",
              fontWeight: "bold",
              height: "64px",
            }}
          >
            {PROJECT_DATA_MANAGEMENT_LABELS.uploadedData}
            <PrepareDataButton />
          </Box>
          <Box
            sx={{
              paddingX: "30px",
              paddingBottom: "30px",
            }}
          >
            <UploadedDataTable
              uploadedData={uploadedData}
              isFetchingForTheFirstTime={isFetchingForTheFirstTime}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

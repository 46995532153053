import { CloudRegistrationTask } from "@custom-types/sdb-background-tasks-types";
import { RegistrationRevision } from "@faro-lotv/service-wires";

/** Headers for the prepared data table */
export enum PreparedDataTableHeaders {
  /** Status of the entity */
  status = "Status",

  /** Progress of registration */
  progress = "Progress",

  /** User who created the entity */
  createdBy = "Created by",

  /** The date when the entity was created */
  createdAt = "Created at",

  /** User who last updated the entity */
  updatedBy = "Updated by",

  /** The date when the entity was last updated */
  updatedAt = "Update date",

  /** Action buttons or additional info to show (Ex: loading spinner indicator)  */
  actions = "Actions",
}

/** Base prepare data type */
export interface PreparedDataBase extends RegistrationRevision {
  /** ProgressAPI task associated to the registration */
  task?: CloudRegistrationTask;
}

/** All possible prepared data types */
export type PreparedData = PreparedDataBase;

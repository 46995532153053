import {
  Divider,
  PopperProps,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { sphereColors } from "@styles/common-colors";
import { SphereAvatar } from "@components/header/sphere-avatar";
import ContainsDescriptionSVG from "@assets/icons/new/contains-description.svg?react";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { withEllipsis } from "@styles/common-styles";
import { getTeamAvatarInitials } from "@utils/team-utils";

/** Time to wait between hover and open the dialog */
const enterDelay = 200;

interface Props {
  /** The team data to be displayed */
  team: SphereDashboardAPITypes.ITeam;
}

/** Popper props to be used in the tooltip */
const tooltipPopperProps: Partial<PopperProps> = {
  sx: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "white",
      borderRadius: "4px",
      boxShadow: "0px 3px 3px 0px rgba(133, 146, 173, 0.20)",
    },
  },
};

/**
 * The TeamName component displays a team's group name and, if available, a description of the group.
 */
export function TeamName({ team }: Props): JSX.Element {
  const styles = useMemo(
    () => ({
      stack: {
        gap: "8px",
        flexDirection: "row",
        alignItems: "center",
        "&:hover": {
          color: sphereColors.blue500,
        },
        width: "100%",
      },
    }),
    []
  );

  return (
    <Tooltip
      enterDelay={enterDelay}
      enterNextDelay={enterDelay}
      PopperProps={tooltipPopperProps}
      placement="right"
      title={
        team.description && (
          <Stack component="div" padding={"8px"}>
            <Typography
              color={sphereColors.gray800}
              fontWeight={700}
              fontSize={"12px"}
            >
              Description
            </Typography>
            <Divider />

            <Typography
              component={"var"}
              color={sphereColors.gray800}
              fontSize={"12px"}
              paddingTop={"8px"}
            >
              {team.description}
            </Typography>
          </Stack>
        )
      }
    >
      <Stack component="div" sx={styles.stack}>
        <SphereAvatar
          initials={getTeamAvatarInitials(team.name)}
          size="medium"
        />
        <Typography
          component={"var"}
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            ...withEllipsis,
          }}
        >
          {team.name}
        </Typography>

        {team.description && (
          <ContainsDescriptionSVG data-testid="description-svg" />
        )}
      </Stack>
    </Tooltip>
  );
}

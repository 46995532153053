import { ExportAsCSV } from "@utils/export-as-csv";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { MemberTypes } from "@custom-types/member-types";
import { useAppSelector } from "@store/store-helper";
import { downloadMembersDataAsCSV } from "@utils/csv-data-export";
import { tableTypeSelector } from "@store/table/table-selector";

interface Props {
  /** The entities that are selected */
  selectedEntities: MemberTypes[];

  /** Recognizes on which context the table is being used */
  subjectType: MemberTableSubject;

  /** The name of the context type */
  entityName: string;

  /** True if some entities are selected */
  areEntitiesSelected: boolean;
}

/** Button to export selected members as CSV */
export function BulkMemberCsvExport({
  subjectType,
  entityName,
  selectedEntities,
  areEntitiesSelected,
}: Props): JSX.Element {
  const tableType = useAppSelector(tableTypeSelector);

  return (
    <ExportAsCSV
      trackingProps={{
        dataType: tableType ?? undefined,
        numberOfEntities: selectedEntities.length,
        areEntitiesSelected,
      }}
      csvExportHandler={() => {
        downloadMembersDataAsCSV({
          fileName: generateFileName(subjectType, entityName),
          members: selectedEntities,
          subjectType: subjectType,
        });
      }}
      toastMessage={{
        success: "Successfully exported members as CSV.",
        error: "Cannot export members. Please try again.",
      }}
      buttonTooltipText="Export members"
    />
  );
}

/** Returns the file name for the CSV file */
function generateFileName(
  subjectType: MemberTableSubject,
  entityName: string
): string {
  switch (subjectType) {
    case "workspace":
      return `Company ${entityName} - Member List.csv`;

    case "project":
      return `Project ${entityName} - Member List.csv`;

    case "group":
      return `Group ${entityName} - Member List.csv`;

    case "snapshot":
      return `Snapshot ${entityName} - Member List.csv`;

    case "team":
      return `Team ${entityName} - Member List.csv`;
  }
}

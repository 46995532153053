import { MemberTypes } from "@custom-types/member-types";
import { AvatarGroup, Grid } from "@mui/material";
import { TeamMemberAvatar } from "@pages/project-details/project-overview/team-member-avatar";
import { LabelWithHelp } from "@components/common/label-with-help";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** List of member */
  members: MemberTypes[];

  /** Maximum number of avatars need to display */
  maxMemberAvatars: number;

  /** Title for the avatar groups */
  label?: string;
}

/** Renders members avatar group */
export function MemberAvatars({
  members,
  maxMemberAvatars,
  label,
}: Props): JSX.Element {
  return (
    <Grid container alignItems="flex-end" justifyContent="space-between">
      <Grid item>
        {label && (
          <LabelWithHelp
            title={label}
            sx={{
              color: sphereColors.gray500,
              fontWeight: "normal",
              fontSize: "11px",
            }}
          />
        )}

        <AvatarGroup
          sx={{ justifyContent: "flex-end" }}
          max={maxMemberAvatars + 1}
        >
          {/* Only show the first section of the members */}
          {members.slice(0, maxMemberAvatars).map((member) => (
            <TeamMemberAvatar key={member.identity} member={member} />
          ))}
          {members.length > maxMemberAvatars && (
            <TeamMemberAvatar
              key="more-members"
              // Show the remaining members
              members={members.slice(maxMemberAvatars)}
            />
          )}
        </AvatarGroup>
      </Grid>
    </Grid>
  );
}

import { PageInfoBar } from "@components/common/page-info-bar";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { useAppSelector } from "@store/store-helper";
import {
  fetchingStatusTeamsSelector,
  teamsSelector,
} from "@store/teams/teams-selector";
import { CreateTeam } from "@pages/members/teams/create-team";
import { TEAMS_DISPLAY_NAME } from "@src/constants/team-constants";
import { FetchingStatus } from "@store/store-types";

/**
 * Shows the main details for the user groups
 */
export function TeamsDetails(): JSX.Element {
  const teams = useAppSelector(teamsSelector);
  const fetchingTeamStatus = useAppSelector(fetchingStatusTeamsSelector);

  if (fetchingTeamStatus === FetchingStatus.pending) {
    return <PageInfoBar isLoading={true} />;
  }

  return (
    <PageInfoBar
      items={[
        {
          content: (
            <FaroTextField
              label={`Total ${TEAMS_DISPLAY_NAME}`}
              initialValue={teams.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
      action={<CreateTeam />}
    />
  );
}

import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { getRegistrationApiClient } from "@api/registration-api/registration-api-utils";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { FaroTextButton } from "@components/common/faro-text-button";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { Typography } from "@mui/material";
import { hasUploadedDataProcessingSelector } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-selectors";
import { fetchAllRegistrationRevisions } from "@store/capture-tree/capture-tree-thunks";
import { fetchTasksByType } from "@store/sdb-background-tasks/sdb-background-tasks-thunk";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { sphereColors } from "@styles/common-colors";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCallback, useState } from "react";

/** Renders a button that triggers the data preparation workflow */
export function PrepareDataButton(): JSX.Element {
  const { projectId } = useDataManagementContext();
  const { trackAsyncEvent } = useTrackEvent();
  const { createDialog } = useDialog();
  const { showToast } = useToast();
  const { handleErrorWithToast, handleErrorSilently } = useErrorContext();
  const currentUser = useAppSelector(currentUserSelector);
  const hasUploadedDataProcessing = useAppSelector(
    hasUploadedDataProcessingSelector
  );
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const progressApiClient = useProgressApiClient({
    projectId: projectId.toString(),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Fetches the cloud registration tasks and registrations 1 second after the registration
   * is triggered in order to show registration progress to the users immediately.
   * Is not critical if the fetch operation fails so don't show any error to the user.
   */
  const updateTasks = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- 1 second
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const tasksPromise = dispatch(
        fetchTasksByType({
          progressApiClient,
          taskType: "CloudRegistration",
        })
      ).unwrap();
      const revisionsPromise = dispatch(
        fetchAllRegistrationRevisions({ projectApiClient })
      ).unwrap();
      await Promise.all([tasksPromise, revisionsPromise]);
    } catch (error) {
      handleErrorSilently({
        id: `updateRegistrationRevisions-${Date.now().toString()}`,
        title: "Failed to update registration revisions.",
        error,
      });
    }
  }, [dispatch, handleErrorSilently, progressApiClient, projectApiClient]);

  /**
   * Triggers a multi cloud registration.
   * First checks if there are still scans being processed and shows a warning to the user.
   * After the multi cloud registration has successfully started the cloud registration tasks
   * are fetched in order to show the registration progress to the user.
   */
  const onClick = useCallback(async () => {
    let shouldPrepareData = true;

    if (hasUploadedDataProcessing) {
      shouldPrepareData = await createDialog(
        {
          title: "Prepare data",
        },
        <>
          <Typography
            sx={{
              marginBottom: "24px",
            }}
          >
            Some scans are still processing. If you continue they won't be
            included in the data preparation process.
          </Typography>
          <Typography>Do you want to continue?</Typography>
        </>
      );
    }

    if (shouldPrepareData && projectId && currentUser && currentUser.id) {
      trackAsyncEvent({
        name: DataManagementEvents.triggerCaptureTreeRegistration,
      });
      setIsLoading(true);

      try {
        const registrationApiClient = getRegistrationApiClient({
          projectId,
          userId: currentUser.id,
        });

        await registrationApiClient.startCaptureTreeRegistration({});

        showToast({
          message: "Multi cloud registration started successfully.",
          description:
            "Go to Prepared Data to track the registration progress.",
          type: "success",
        });

        updateTasks();
      } catch (error) {
        handleErrorWithToast({
          id: `startCaptureTreeRegistration-${Date.now().toString()}`,
          title: "Error starting a multi cloud registration",
          error,
        });
      }

      setIsLoading(false);
    }
  }, [
    createDialog,
    currentUser,
    handleErrorWithToast,
    hasUploadedDataProcessing,
    projectId,
    showToast,
    trackAsyncEvent,
    updateTasks,
  ]);

  return (
    <FaroTextButton
      onClick={onClick}
      tooltipText="Prepare data"
      size="large"
      isDisabled={isLoading}
      dataTestId="prepare-data-button"
    >
      Prepare data
      <FaroButtonSpinner
        shouldHide={!isLoading}
        loadingIndicatorColor={sphereColors.blue600}
        loadingTrackColor={sphereColors.gray100}
      />
    </FaroTextButton>
  );
}

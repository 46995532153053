import { BackgroundTaskStates } from "@api/progress-api/progress-api-types";
import { FaroTable } from "@components/common/faro-table/faro-table";
import { useBackgroundTasksTracker } from "@hooks/background-tasks/use-background-tasks-tracker";
import {
  ProjectId,
  UserId,
} from "@stellar/api-logic/dist/api/core-api/api-types";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { useAppSelector } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import { useEffect, useState } from "react";
import {
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { FaroTableMemberCell } from "@components/common/faro-table/faro-table-member-cell";
import { FaroTableTextCell } from "@components/common/faro-table/faro-table-text-cell";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { FaroChip, FaroChipProps } from "@faro-lotv/flat-ui";
import { getMemberNameById } from "@utils/member-utils";
import { DASH } from "@utils/ui-utils";
import { DateTime } from "luxon";
import { BackgroundTaskProgress } from "@pages/project-details/project-bg-tasks/background-task-progress";
import { isPreparedDataProcessing } from "@pages/project-details/project-data-management/prepared-data/prepared-data-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { MemberTypes } from "@custom-types/member-types";
import { FormatDate, useDateTime } from "@hooks/use-date-time";
import { projectMembersSelector } from "@store/projects/projects-selector";

enum PhotogrammetryTaskTableHeaders {
  /** Status of the entity */
  status = "Status",

  /** Progress of registration */
  progress = "Progress",

  /** User who created the entity */
  createdBy = "Created by",

  /** The date when the entity was created */
  createdAt = "Created at",
}

const COLUMNS: PhotogrammetryTaskTableHeaders[] = [
  PhotogrammetryTaskTableHeaders.status,
  PhotogrammetryTaskTableHeaders.progress,
  PhotogrammetryTaskTableHeaders.createdBy,
  PhotogrammetryTaskTableHeaders.createdAt,
];

interface PhotogrammetryTaskReport {
  id: string;
  status: BackgroundTaskStates;
  progress: string;
  createdBy: UserId;
  createdAt: string;
}

type ColorProps = Required<
  Pick<FaroChipProps, "color" | "backgroundColor" | "borderColor">
>;

export const PHOTOGRAMMETRY_STATE_COLORS: {
  [key in BackgroundTaskStates]: ColorProps;
} = {
  Created: {
    color: sphereColors.gray800,
    backgroundColor: sphereColors.pureWhite,
    borderColor: sphereColors.gray200,
  },
  Scheduled: {
    color: sphereColors.yellow600,
    backgroundColor: sphereColors.yellow100,
    borderColor: sphereColors.gray200,
  },
  Started: {
    color: sphereColors.yellow600,
    backgroundColor: sphereColors.yellow100,
    borderColor: sphereColors.yellow200,
  },
  Succeeded: {
    color: sphereColors.green600,
    backgroundColor: sphereColors.green50,
    borderColor: sphereColors.green100,
  },
  Aborted: {
    color: sphereColors.gray800,
    backgroundColor: sphereColors.pureWhite,
    borderColor: sphereColors.gray200,
  },
  Failed: {
    color: sphereColors.gray800,
    backgroundColor: sphereColors.pureWhite,
    borderColor: sphereColors.gray200,
  },
};
interface Props {
  projectId: ProjectId;
}

export function PhotogrammetryTasks({ projectId }: Props): JSX.Element | null {
  const backgroundTaskTracker = useBackgroundTasksTracker({
    projectId: projectId.toString(),
  });
  const backgroundTasks = useAppSelector(sdbBackgroundTasksSelector);
  const projectMembers = useAppSelector(projectMembersSelector);
  const { formatDate } = useDateTime();
  const [photogrammetryTasks, setPhotogrammetryTasks] = useState<
    PhotogrammetryTaskReport[]
  >([]);

  useEffect(() => {
    setPhotogrammetryTasks(
      backgroundTasks
        .filter((t): boolean => {
          return t.taskType === "Photogrammetry";
        })
        .map((t): PhotogrammetryTaskReport => {
          return {
            id: t.id,
            status: (t.status as BackgroundTaskStates) ?? "Scheduled",
            progress: t.progress ?? "0",
            createdBy: t.context?.userId ?? "",
            createdAt: t.createdAt,
          };
        })
    );
  }, [backgroundTasks, setPhotogrammetryTasks]);

  function getPreparedDataTableColumns(
    companyMembers: MemberTypes[],
    projectMembers: SphereDashboardAPITypes.IProjectMemberBase[],
    formatDate: FormatDate
  ): Record<PhotogrammetryTaskTableHeaders, GridColDef> {
    return {
      [PhotogrammetryTaskTableHeaders.status]: {
        field: PhotogrammetryTaskTableHeaders.status,
        minWidth: 160,
        flex: 0.75,
        renderCell: (
          params: GridRenderCellParams<{ entity: PhotogrammetryTaskReport }>
        ) => {
          const state = params.row.entity.status;
          return (
            <FaroChip
              label={state}
              tooltip={state}
              color={PHOTOGRAMMETRY_STATE_COLORS[state].color}
              backgroundColor={
                PHOTOGRAMMETRY_STATE_COLORS[state].backgroundColor
              }
              borderColor={PHOTOGRAMMETRY_STATE_COLORS[state].borderColor}
              variant="outlined"
              sx={{
                maxWidth: "150px",
              }}
            />
          );
        },
        valueGetter: (_, row: { entity: PhotogrammetryTaskReport }) =>
          row.entity.status,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
        sortable: true,
        sortComparator: (v1, v2, cp1, cp2) =>
          faroTableComparator<string>(
            v1,
            v2,
            cp1,
            cp2,
            gridStringOrNumberComparator
          ),
      },

      [PhotogrammetryTaskTableHeaders.progress]: {
        field: PhotogrammetryTaskTableHeaders.progress,
        minWidth: 120,
        flex: 0.75,
        renderCell: (
          params: GridRenderCellParams<{ entity: PhotogrammetryTaskReport }>
        ) => {
          /**
           * Only display the progress component for PreparedData entities that have their task
           * status value as "Started". Reason is that only tasks of status "Started" have a progress number,
           * tasks of other status value show something else as progress. For now we wan't to keep it simple
           * and only show a progress number if available, otherwise show a DASH char.
           */
          return params.row.entity.status === "Started" ? (
            <BackgroundTaskProgress taskProgress={params.row.entity.progress} />
          ) : (
            DASH
          );
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
        sortable: false,
      },

      [PhotogrammetryTaskTableHeaders.createdBy]: {
        field: PhotogrammetryTaskTableHeaders.createdBy,
        minWidth: 180,
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<{ entity: PhotogrammetryTaskReport }>
        ) => {
          return (
            <FaroTableMemberCell
              memberId={params.row.entity.createdBy}
              companyMembers={companyMembers}
              projectMembers={projectMembers}
            />
          );
        },
        valueGetter: (_, row: { entity: PhotogrammetryTaskReport }) =>
          getMemberNameById({
            memberId: row.entity.createdBy,
            companyMembers,
            projectMembers,
          }),
        // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
        sortable: true,
        sortComparator: (v1, v2, cp1, cp2) =>
          faroTableComparator<string | undefined>(
            v1,
            v2,
            cp1,
            cp2,
            gridStringOrNumberComparator
          ),
      },

      [PhotogrammetryTaskTableHeaders.createdAt]: {
        field: PhotogrammetryTaskTableHeaders.createdAt,
        minWidth: 180,
        flex: 1,
        type: "date",
        renderCell: (
          params: GridRenderCellParams<{ entity: PhotogrammetryTaskReport }>
        ) => {
          const date = formatDate(
            params.row.entity.createdAt,
            DateTime.DATETIME_MED
          );
          return <FaroTableTextCell text={date} />;
        },
        valueGetter: (_, row: { entity: PhotogrammetryTaskReport }) => {
          return new Date(row.entity.createdAt);
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
        sortable: true,
        sortComparator: (v1, v2, cp1, cp2) =>
          faroTableComparator<Date | undefined>(
            v1,
            v2,
            cp1,
            cp2,
            gridDateComparator
          ),
      },
    };
  }

  return (
    <FaroTable
      entities={photogrammetryTasks}
      uniqueIdKey={"id"}
      isLoading={backgroundTaskTracker.isLoading}
      columns={COLUMNS.map(
        (column) =>
          getPreparedDataTableColumns([], projectMembers, formatDate)[column]
      )}
      rowHeight={54}
      shouldHideCheckbox={true}
      rowClassName={(rowData) => {
        return isPreparedDataProcessing(rowData.row.entity)
          ? "prepared-data-isProcessing"
          : "";
      }}
      sx={{
        // Set background color of preparing rows
        "& .prepared-data-isProcessing": {
          background: sphereColors.blue50,
          "&:hover": {
            background: sphereColors.blue50,
          },
        },
        // Set opacity of all cells in preparing rows
        "& .prepared-data-isProcessing *": {
          opacity: "0.8",
        },
        // Especial opacity for the last column that displays actions/indicator
        "& .prepared-data-isProcessing *:last-child *": {
          opacity: "1",
        },
      }}
    />
  );
}

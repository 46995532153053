import { AccessLevelDisplayNames } from "@custom-types/project-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** Returns the label of the current value to render in the select component */
export function getAccessLevelValue(
  accessLevel: SphereDashboardAPITypes.EAccessLevel
): string {
  return AccessLevelDisplayNames[accessLevel];
}

/** Access level descriptions */
export enum AccessLevelLDescription {
  private = "The project can only be seen by members who are logged in and invited.",
  unlisted = "The project can be seen by everyone with the link.",
}

/** Interface for a project access level item */
interface ProjectAccessLevelItem {
  /** Display label */
  label: string;

  /** Access level description */
  description: string;

  /** Access level value */
  value: SphereDashboardAPITypes.EAccessLevel;
}

/** Project access levels array */
export const PROJECT_ACCESS_LEVELS: ProjectAccessLevelItem[] = [
  {
    label: AccessLevelDisplayNames.private,
    description: AccessLevelLDescription.private,
    value: SphereDashboardAPITypes.EAccessLevel.private,
  },
  {
    label: AccessLevelDisplayNames.unlisted,
    description: AccessLevelLDescription.unlisted,
    value: SphereDashboardAPITypes.EAccessLevel.unlisted,
  },
];

import { useAppSelector } from "@store/store-helper";
import { PhotogrammetryTabs, QueryParams } from "@router/route-params";
import { PhotogrammetryUpload } from "@pages/project-details/photogrammetry-upload/photogrammetry-upload";
import { PhotogrammetryTasks } from "@pages/project-details/photogrammetry-upload/photogrammetry-tasks";
import {
  projectIdsSelector,
  selectedProjectIdSelector,
} from "@store/projects/projects-selector";
import { FaroTabs } from "@faro-lotv/flat-ui";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { sphereColors } from "@styles/common-colors";
import { useState } from "react";
import { Box } from "@mui/material";
import { ProjectId } from "@stellar/api-logic/dist/api/core-api/api-types";

/** Renders project data management tabs */
export function PhotogrammetryOverview(): JSX.Element {
  const selectedProjectId = GetProjectID();
  const [selectedTab, setSelectedTab] = useState<PhotogrammetryTabs>(
    PhotogrammetryTabs.upload
  );
  const { setUrlParam } = useAppNavigation();

  function GetProjectID(): ProjectId {
    const pid = useAppSelector(selectedProjectIdSelector);
    const ids: ProjectId = useAppSelector(projectIdsSelector)[0];
    if (pid != null) {
      return pid;
    }
    return ids;
  }

  const items = [
    {
      value: PhotogrammetryTabs.upload,
      title: "Upload",
      description: "Upload photogrammetry data",
      icon: undefined,
    },
    {
      value: PhotogrammetryTabs.datasets,
      title: "Datasets",
      description: "Tasks running",
      icon: undefined,
    },
  ];

  function tabChanged(value: PhotogrammetryTabs): void {
    setSelectedTab(value);
    setUrlParam({
      key: QueryParams.type,
      value: value,
    });
  }

  return (
    <Box>
      <FaroTabs
        items={items}
        initialValue={PhotogrammetryTabs.upload}
        onTabChange={tabChanged}
        sx={{
          padding: "10px",
          border: `1px solid ${sphereColors.gray200}`,
          borderRadius: 0,
          background: "white",
          "& [role='determinate-progress'] circle, .MuiButtonBase-root:hover [role='determinate-progress'] circle":
            {
              color: "#E0E4EB",
            },
        }}
      />

      {selectedTab === PhotogrammetryTabs.upload && (
        <PhotogrammetryUpload projectId={selectedProjectId} />
      )}
      {selectedTab === PhotogrammetryTabs.datasets && (
        <PhotogrammetryTasks projectId={selectedProjectId} />
      )}
    </Box>
  );
}

import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { hasCreateGroupsPermission } from "@utils/permission-utils";
import {
  HasUserValidRoleCompanyLevelProps,
  RequiredRoleCompanyLevelName,
  RequiredRolesCompanyLevel,
} from "@utils/access-control/company/company-access-control-types";

const companyRole = CoreAPITypes.EUserCompanyRole;

/**
 * Object that determines all different permission rules for users to get
 * access on the company level.
 */
export const requiredRolesCompanyLevel: RequiredRolesCompanyLevel<RequiredRoleCompanyLevelName> =
  {
    [RequiredRoleCompanyLevelName.canViewAllCompanyUsers]: {
      companyRoles: [companyRole.companyExecutive, companyRole.companyViewer],
    },

    [RequiredRoleCompanyLevelName.canViewCompanyMemberProfile]: {
      companyRoles: [companyRole.companyExecutive, companyRole.companyViewer],
      shouldAllowCurrentUser: true,
    },

    [RequiredRoleCompanyLevelName.canChangeDisplayName]: {
      companyRoles: [companyRole.companyExecutive],
      shouldAllowCurrentUser: true,
    },

    [RequiredRoleCompanyLevelName.canCreateProjects]: {
      companyRoles: [
        companyRole.companyExecutive,
        companyRole.companyManager,
        companyRole.projectManager,
      ],
    },

    [RequiredRoleCompanyLevelName.canInviteUsersToCompany]: {
      companyRoles: [companyRole.companyExecutive],
    },

    [RequiredRoleCompanyLevelName.canEditMember]: {
      companyRoles: [companyRole.companyExecutive],
    },

    [RequiredRoleCompanyLevelName.canViewAllCompanyGroups]: {
      companyRoles: [
        companyRole.companyExecutive,
        companyRole.companyViewer,
        companyRole.companyManager,
        companyRole.projectManager,
      ],
    },

    [RequiredRoleCompanyLevelName.canCreateGroups]: {
      companyRoles: [companyRole.companyExecutive],
      companyManager: { shouldCreateGroups: true },
    },

    [RequiredRoleCompanyLevelName.canViewAnalytics]: {
      companyRoles: [companyRole.companyExecutive, companyRole.companyViewer],
      companySubscriptionRoles: [
        APITypes.EUserSubscriptionRole.dashboardInsights,
      ],
    },

    [RequiredRoleCompanyLevelName.canViewWorkspaceSettings]: {
      // !: Fetching the branding settings for a workspace fails for enterprise viewers, hence we hide the page.
      companyRoles: [companyRole.companyExecutive],
    },

    [RequiredRoleCompanyLevelName.canViewPremiumAnalytics]: {
      companyRoles: [companyRole.companyExecutive, companyRole.companyViewer],
      companySubscriptionRoles: [
        APITypes.EUserSubscriptionRole.companyInsights,
      ],
    },

    [RequiredRoleCompanyLevelName.canDeleteMemberFromCompany]: {
      companyRoles: [companyRole.companyExecutive],
    },

    [RequiredRoleCompanyLevelName.canEditDefaultPlatform]: {
      companyRoles: [companyRole.companyExecutive],
    },

    [RequiredRoleCompanyLevelName.canViewTeams]: {
      companyRoles: [companyRole.companyExecutive],
    },

    [RequiredRoleCompanyLevelName.canEditTeams]: {
      companyRoles: [companyRole.companyExecutive],
    },

    [RequiredRoleCompanyLevelName.canInviteMembersToTeam]: {
      companyRoles: [companyRole.companyExecutive],
    },
  };

/**
 * Checks whether a user has at least one of the required company roles.
 * !: Only use this method internally
 * To check for user access control better use hasUserValidRoleCompanyLevel.
 * In some cases the user might not have the required company role but could still
 * have access because a different role within the group.
 *
 * @param currentUser The user to get its company role from.
 * @param requiredCompanyRoles Array filled with company roles
 * that the user should have one of them
 * @returns True if the user has one of the required company roles.
 */
export function hasUserValidCompanyRole({
  currentUser,
  requiredCompanyRoles,
}: {
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null;
  requiredCompanyRoles: CoreAPITypes.EUserCompanyRole[];
}): boolean {
  if (!currentUser) {
    // If there's no current user we assume that the user
    // is not logged in and therefore should not have access.
    return false;
  }
  if (!requiredCompanyRoles.length) {
    // If the requiredCompanyRoles were not provided or the
    // array was empty, we assume the user has a valid role.
    return true;
  }

  return requiredCompanyRoles.includes(currentUser.role);
}

/**
 * Checks whether a user has permissions within the company level.
 *
 * @returns True if the user has a valid company level role or permission.
 */
export function hasUserValidRoleCompanyLevel<
  RoleNameT extends string = RequiredRoleCompanyLevelName
>({
  roleName,
  currentUser,
  defaultRequiredRolesCompanyLevel = requiredRolesCompanyLevel as RequiredRolesCompanyLevel<RoleNameT>,
  memberId,
  companyContext,
}: HasUserValidRoleCompanyLevelProps<RoleNameT>): boolean {
  if (!roleName) {
    return true;
  }

  // Check first if user has enabled company level subscription roles
  // Since the subscription access is a required condition the roles should be checked first
  // Early exit with false here if the subscription is not available
  if (
    defaultRequiredRolesCompanyLevel[roleName].companySubscriptionRoles &&
    companyContext &&
    !hasCompanySubscriptionRoles({
      companyContext,
      requiredCompanySubscriptionRole:
        defaultRequiredRolesCompanyLevel[roleName].companySubscriptionRoles,
    })
  ) {
    return false;
  }

  const requiredCompanyRoles =
    defaultRequiredRolesCompanyLevel[roleName].companyRoles;
  if (!requiredCompanyRoles) {
    return true;
  }

  // Check if the user has enabled the permission to create groups.
  if (
    defaultRequiredRolesCompanyLevel[roleName].companyManager
      ?.shouldCreateGroups &&
    hasCreateGroupsPermission({ member: currentUser })
  ) {
    return true;
  }

  // When the user has access to something because he is performing an action on himself,
  // like accessing his own member profile page or changing his own password.
  if (
    defaultRequiredRolesCompanyLevel[roleName].shouldAllowCurrentUser &&
    memberId &&
    currentUser?.id &&
    memberId === currentUser.id
  ) {
    return true;
  }

  return hasUserValidCompanyRole({ currentUser, requiredCompanyRoles });
}

/**
 * Checks whether the user has required company subscription for the user.
 * !: Only use this method internally
 * To check for user subscription role better use hasUserValidRoleCompanyLevel.
 *
 * @param companyContext The context of the selected company.
 * @param requiredCompanySubscriptionRole Array filled with required User subscription roles
 * that the user should have one of them
 * @returns True if the user has one of the required company subscription.
 */
export function hasCompanySubscriptionRoles({
  companyContext,
  requiredCompanySubscriptionRole,
}: {
  companyContext: APITypes.ICompanyContext;
  requiredCompanySubscriptionRole?: APITypes.EUserSubscriptionRole[];
}): boolean {
  /**
   * Early return if there is no required company subscription
   * In this case there is no required subscriptions
   */
  if (!requiredCompanySubscriptionRole?.length) {
    return true;
  }

  /**
   * Early return if no user roles in company context
   * In this case required subscription is not available
   * as the userRoles in companyContext is empty
   */
  if (!companyContext.userRoles.length) {
    return false;
  }

  // Return true if all required subscription role is available
  return requiredCompanySubscriptionRole.every((requiredSubscriptionRole) =>
    companyContext.userRoles.includes(requiredSubscriptionRole)
  );
}

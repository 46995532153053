import { MemberTypes } from "@custom-types/member-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { PreparedDataTableHeaders } from "@pages/project-details/project-data-management/prepared-data/prepared-data-types";
import {
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { FaroTableTextCell } from "@components/common/faro-table/faro-table-text-cell";
import { FaroTableMemberCell } from "@components/common/faro-table/faro-table-member-cell";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { getMemberNameById } from "@utils/member-utils";
import {
  REGISTRATION_STATE_COLORS,
  REGISTRATION_STATE_LABEL,
} from "@pages/project-details/project-data-management/prepared-data/prepared-data-constants";
import { FaroChip } from "@faro-lotv/flat-ui";
import { PreparedDataActions } from "@pages/project-details/project-data-management/prepared-data/prepared-data-actions";
import { DateTime } from "luxon";
import { FormatDate } from "@hooks/use-date-time";
import { PreparedData } from "@pages/project-details/project-data-management/prepared-data/prepared-data-types";
import { BackgroundTaskProgress } from "@pages/project-details/project-bg-tasks/background-task-progress";
import { DASH } from "@utils/ui-utils";

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];

  /** Function to format date */
  formatDate: FormatDate;
}

/**
 * Returns a an object with the prepared data table columns:
 * - Each property key is the column name
 * - Each property value is a GridColDef object of the MUI data grid
 */
export function getPreparedDataTableColumns({
  companyMembers,
  projectMembers,
  formatDate,
}: Props): Record<PreparedDataTableHeaders, GridColDef> {
  return {
    [PreparedDataTableHeaders.status]: {
      field: PreparedDataTableHeaders.status,
      minWidth: 160,
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<{ entity: PreparedData }>) => {
        const state = params.row.entity.state;
        return (
          <FaroChip
            label={REGISTRATION_STATE_LABEL[state]}
            tooltip={REGISTRATION_STATE_LABEL[state]}
            color={REGISTRATION_STATE_COLORS[state].color}
            backgroundColor={REGISTRATION_STATE_COLORS[state].backgroundColor}
            borderColor={REGISTRATION_STATE_COLORS[state].borderColor}
            variant="outlined"
            sx={{
              maxWidth: "150px",
            }}
          />
        );
      },
      valueGetter: (_, row: { entity: PreparedData }) => row.entity.state,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [PreparedDataTableHeaders.progress]: {
      field: PreparedDataTableHeaders.progress,
      minWidth: 120,
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<{ entity: PreparedData }>) => {
        /**
         * Only display the progress component for PreparedData entities that have their task
         * status value as "Started". Reason is that only tasks of status "Started" have a progress number,
         * tasks of other status value show something else as progress. For now we wan't to keep it simple
         * and only show a progress number if available, otherwise show a DASH char.
         */
        return params.row.entity.task?.status === "Started" ? (
          <BackgroundTaskProgress
            taskProgress={params.row.entity.task?.progress}
          />
        ) : (
          DASH
        );
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: false,
    },

    [PreparedDataTableHeaders.createdBy]: {
      field: PreparedDataTableHeaders.createdBy,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: PreparedData }>) => {
        return (
          <FaroTableMemberCell
            memberId={params.row.entity.createdBy}
            companyMembers={companyMembers}
            projectMembers={projectMembers}
          />
        );
      },
      valueGetter: (_, row: { entity: PreparedData }) =>
        getMemberNameById({
          memberId: row.entity.createdBy,
          companyMembers,
          projectMembers,
        }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [PreparedDataTableHeaders.createdAt]: {
      field: PreparedDataTableHeaders.createdAt,
      minWidth: 180,
      flex: 1,
      type: "date",
      renderCell: (params: GridRenderCellParams<{ entity: PreparedData }>) => {
        const date = formatDate(
          params.row.entity.createdAt,
          DateTime.DATETIME_MED
        );
        return <FaroTableTextCell text={date} />;
      },
      valueGetter: (_, row: { entity: PreparedData }) => {
        return new Date(row.entity.createdAt);
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<Date | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridDateComparator
        ),
    },

    [PreparedDataTableHeaders.updatedBy]: {
      field: PreparedDataTableHeaders.updatedBy,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: PreparedData }>) => {
        return (
          <FaroTableMemberCell
            memberId={params.row.entity.modifiedBy}
            companyMembers={companyMembers}
            projectMembers={projectMembers}
          />
        );
      },
      valueGetter: (_, row: { entity: PreparedData }) =>
        getMemberNameById({
          memberId: row.entity.modifiedBy,
          companyMembers,
          projectMembers,
        }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [PreparedDataTableHeaders.updatedAt]: {
      field: PreparedDataTableHeaders.updatedAt,
      minWidth: 180,
      flex: 1,
      type: "date",
      renderCell: (params: GridRenderCellParams<{ entity: PreparedData }>) => {
        const date = formatDate(
          params.row.entity.modifiedAt,
          DateTime.DATETIME_MED
        );
        return <FaroTableTextCell text={date} />;
      },
      valueGetter: (_, row: { entity: PreparedData }) => {
        return new Date(row.entity.modifiedAt);
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<Date | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridDateComparator
        ),
    },

    [PreparedDataTableHeaders.actions]: {
      field: PreparedDataTableHeaders.actions,
      type: "actions",
      align: "right",
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: PreparedData }>) => {
        return <PreparedDataActions preparedData={params.row.entity} />;
      },
    },
  };
}

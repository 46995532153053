import { TeamState } from "@custom-types/teams-types";
import {
  AddMembersToTeamResult,
  RemoveTeamMembersResult,
} from "@store/teams/teams-slice-types";
import { teamAdapter } from "@store/teams/teams-slice";

/**
 * Removes locally a member from a team, by removing it from sample members array
 * attribute of the team if members are available.
 */
export function removeLocalMemberFromTeam(
  state: TeamState,
  result: RemoveTeamMembersResult
): void {
  const team = teamAdapter.getSelectors().selectById(state, result.teamId);
  if (team) {
    const newMembers = team.sampleMembers?.filter(
      (member) => !result.memberIds.includes(member.userResponse.identity)
    );

    teamAdapter.updateOne(state, {
      id: team.id,
      changes: {
        sampleMembers: newMembers,
      },
    });
  }
}

/**
 * Adds sample members in team store.
 */
export function addMembersToTeamStore(
  state: TeamState,
  result: AddMembersToTeamResult
): void {
  const team = teamAdapter.getSelectors().selectById(state, result.teamId);

  // If the team is in the store update the sampleMembers
  if (team) {
    const newSampleMembers = [...team.sampleMembers, ...result.data];

    teamAdapter.updateOne(state, {
      id: team.id,
      changes: {
        sampleMembers: newSampleMembers,
      },
    });
  }
}

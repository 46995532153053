import { MemberTypes } from "@custom-types/member-types";
import { Box } from "@mui/material";
import { DEFAULT_FONT_FAMILY_ITALIC } from "@styles/common-styles";
import { getPrettyName } from "@utils/user-utils";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";

interface Props {
  /** The member that is been removed */
  member: MemberTypes;

  /** The entity type from where the member is being removed */
  subjectType: MemberTableSubject;

  /** The name of the entity from where the member is being removed */
  entityName: string;
}

/** Renders the description from success message after removing a member */
export function RemoveMemberSuccessDescription({
  member,
  subjectType,
  entityName,
}: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        fontSize: "14px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        component="var"
        sx={{
          fontFamily: DEFAULT_FONT_FAMILY_ITALIC,
          wordWrap: "break-word",
        }}
      >
        {getPrettyName(member)}
      </Box>
      <Box component="div">
        {` has been successfully removed from ${subjectType} `}
        <Box
          component="var"
          sx={{
            fontFamily: DEFAULT_FONT_FAMILY_ITALIC,
          }}
        >
          {entityName}
        </Box>
      </Box>
    </Box>
  );
}

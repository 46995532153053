import {
  Autocomplete,
  AutocompleteChangeReason,
  Popper,
  PopperProps,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getUserInitials } from "@utils/user-utils";
import { SyntheticEvent, useMemo, useState } from "react";
import { ChipTag } from "@components/common/members-autocomplete/chip-tag";
import { UserListItem } from "@components/common/members-autocomplete/user-list-item";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { sphereColors } from "@styles/common-colors";
import {
  AutoCompleteMessage,
  FaroTextFieldMessage,
} from "@components/common/faro-text-field/faro-text-field-message";
import { parseTextToEmails } from "@utils/member-utils";
import { LabelWithHelp } from "@components/common/label-with-help";
import {
  AutoCompleteMenuOption,
  SelectedOption,
  getOptionDisabled,
  getOptionDisabledMessage,
  getOptionLabel,
  getOptionOriginalOption,
  getOptionSubtitle,
  getOptionTitle,
  getOptionValue,
} from "@components/common/members-autocomplete/members-autocomplete-utils";

interface Props {
  /** Elements displayed in the dropdown  */
  options: AutoCompleteMenuOption[];

  /**
   * Callback function to update the selected options with. For example, when pressing enter key
   * Reason is why/how an option is added, for example "createOption", "selectOption", "removeOption".
   */
  handleChange(options: string[], reason?: AutocompleteChangeReason): void;

  /** Callback function called when input has change. This means with every character added */
  onInputChange?(text: string): void;

  /**
   * Displayed inside the input field of autocomplete
   *
   * @default "Enter name(s) or email(s)"
   */
  placeHolder?: string;

  /**
   * Callback function to validate new inserted value before adding to the chip
   *
   * @default () => true
   */
  validateNewOption?(option: string): boolean;

  /** Warning of help text for the autocomplete */
  message?: AutoCompleteMessage;

  /**
   * Optional initial value of the autocomplete
   *
   * @default []
   */
  initialValue?: SelectedOption[];

  /** Label help title for the autocomplete */
  labelTitle?: string;

  /** Display the focus or not */
  hasAutoFocus?: boolean;

  /**
   * Determines whether the field is mandatory.
   * The default value is true.
   */
  isRequired?: boolean;
}

// TODO: Refactor to have common logics used in other components https://faro01.atlassian.net/browse/ST-1707
/**
 * This component shows an autocomplete selection that let's the user to
 * select multiple member from a list, and optionally type a valid email.
 * The component is generic enough that can be used for any purpose regarding
 * selecting multiple member. For selecting only one member see MemberAutocomplete.
 */
export function MembersAutocomplete({
  options,
  handleChange,
  onInputChange,
  placeHolder = "Email(s), comma separated",
  validateNewOption = () => true,
  message,
  initialValue = [],
  labelTitle,
  hasAutoFocus = false,
  isRequired = true,
}: Props): JSX.Element {
  const [selectedOptions, setSelectedOptions] =
    useState<SelectedOption[]>(initialValue);
  /** Stores the current text inside the text input. Does not necessarily match a valid email or valid user. */
  const [inputValue, setInputValue] = useState<string>("");

  /** Stores the values of the selected options in lowercase */
  const values: string[] = useMemo(() => {
    return selectedOptions.map((option) => {
      const value = getOptionValue(option);
      return value.toLowerCase();
    });
  }, [selectedOptions]);

  /**
   * Returns whether the options array contains a non deletable option.
   * This is done in useMemo to prevent iterating over the options array too often.
   */
  const hasPreselectedOption: boolean = useMemo(() => {
    return options.some((option) => option.isPreselected);
  }, [options]);

  /**
   * Handles the change event of the autocomplete.
   * Validates the new values and updates the selected options to match only the
   * valid ones and to leave the non deletable ones.
   * It also calls the parent to notify the new selected options.
   *
   * @param event The event object
   * @param value The new value of the autocomplete
   * @param reason One of "createOption", "selectOption", "removeOption", "blur" or "clear".
   */
  function onChange(
    event: SyntheticEvent<Element, Event>,
    value: (string | AutoCompleteMenuOption)[],
    reason: AutocompleteChangeReason
  ): void {
    const validSelectedOptions: (string | AutoCompleteMenuOption)[] =
      value.filter((option) => {
        if (typeof option === "string") {
          return validateNewOption(option);
        }
        return true;
      });
    const validSelectedOptionValues = validSelectedOptions.map((option) =>
      getOptionValue(option)
    );

    if (hasPreselectedOption) {
      options.forEach((option) => {
        if (
          option.isPreselected &&
          !validSelectedOptionValues.includes(getOptionValue(option))
        ) {
          validSelectedOptions.push(option);
          validSelectedOptionValues.push(getOptionValue(option));
        }
      });
    }
    setSelectedOptions(validSelectedOptions);
    handleChange(validSelectedOptionValues, reason);
  }

  /**
   * On blur of the autocomplete input, if the input value is valid, it adds it to the selected options.
   */
  function addOptionIfValid(): void {
    if (!inputValue) {
      return;
    }
    if (values.includes(inputValue)) {
      return;
    }
    if (validateNewOption(inputValue)) {
      const newOptions = [...selectedOptions, inputValue];
      setSelectedOptions(newOptions);
      handleChange(newOptions.map((option) => getOptionValue(option)));
      setInputValue("");
    }
  }

  /**
   * Handles the change event of the autocomplete input.
   * It trims the value and calls the parent to notify the new input value.
   * It also stores the input value in the local state.
   *
   * @param event The event object
   * @param value The new value of the autocomplete input
   */
  function onAutocompleteInputChange(
    event: React.SyntheticEvent,
    value: string
  ): void {
    const emails = parseTextToEmails(value);

    if (emails.length) {
      // Remove the delimiters first and then the emails from the input value
      value = value.replaceAll(";", "").replaceAll(",", "");
      emails.forEach((email) => {
        setSelectedOptions((prev) => [...prev, email]);
        handleChange(
          [...selectedOptions, email].map((option) => getOptionValue(option))
        );
        value = value.replace(email, "").trim();
      });
    }
    setInputValue(value);
    onInputChange?.(value);
  }

  return (
    <>
      {labelTitle && (
        <LabelWithHelp
          title={labelTitle}
          help="To add multiple members/emails, simply separate them by pressing enter, space, semicolon, or comma."
          isRequired={isRequired}
        />
      )}
      <Autocomplete
        value={selectedOptions}
        multiple
        autoHighlight
        options={options.sort((a, b) =>
          getOptionLabel(a).localeCompare(getOptionLabel(b))
        )}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={getOptionLabel}
        onInputChange={onAutocompleteInputChange}
        onChange={onChange}
        inputValue={inputValue}
        filterSelectedOptions
        freeSolo={true}
        autoSelect={false}
        clearOnBlur={false}
        popupIcon={<ArrowDropDownIcon />}
        onBlur={addOptionIfValid}
        renderInput={(params) => (
          <FaroSimpleTextField
            {...params}
            variant="outlined"
            placeholder={placeHolder}
            autoFocus={hasAutoFocus}
            sx={{
              "& .MuiOutlinedInput-root": { paddingY: "2px" },
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <ChipTag
              key={`chip-${index}`}
              tagProps={getTagProps}
              index={index}
              label={getOptionTitle(option)}
              isDisabled={getOptionDisabled(option)}
            />
          ));
        }}
        // Do not show in the options to select, the ones that are already selected
        filterOptions={(options, params) => {
          const lowerInputValue = params.inputValue.toLowerCase();
          return options.filter(
            (option) =>
              !values.includes(getOptionValue(option).toLowerCase()) &&
              getOptionLabel(option).toLowerCase().includes(lowerInputValue)
          );
        }}
        renderOption={(props, option, state) => (
          <li {...props} key={`listOption-${state.index}`}>
            <UserListItem
              item={{
                title: getOptionTitle(option),
                subtitle: getOptionSubtitle(option),
                userInitials: getUserInitials(getOptionOriginalOption(option)),
                userAvatarImg: getOptionOriginalOption(option)?.thumbnailUrl,
              }}
              inputValue={state.inputValue}
              disabledMessage={getOptionDisabledMessage(option)}
            />
          </li>
        )}
        PopperComponent={(props: PopperProps) => (
          <Popper
            {...props}
            sx={{
              // Hiding the no option dropdown when `freeSolo` is false, A
              // As there is no option to disable the no option dropdown
              "& .MuiAutocomplete-noOptions": {
                display: "none",
              },
            }}
            placement="bottom"
          />
        )}
        sx={{
          "& .MuiOutlinedInput-root": { paddingY: "2px" },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor:
                message?.type === "error"
                  ? sphereColors.red600
                  : sphereColors.blue600,
            },
        }}
      />
      <FaroTextFieldMessage message={message} />
    </>
  );
}

import {
  CaptureTreeEntity,
  CaptureTreeEntityType,
  RegistrationRevision,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import {
  allRegistrationRevisionsAdapter,
  captureTreeForMainRevisionAdapter,
} from "@store/capture-tree/capture-tree-slice";
import { CaptureTreeState } from "@store/capture-tree/capture-tree-slice-types";
import { GUID } from "@faro-lotv/foundation";
import {
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";

/** Returns all the capture tree entities (for the current main revision of the selected project) */
export const captureTreeForMainRevisionSelector: (
  state: RootState
) => CaptureTreeEntity[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return captureTreeForMainRevisionAdapter
      .getSelectors()
      .selectAll(state.captureTree.captureTreeForMainRevision);
  }
);

/** Returns the fetching status of the capture tree entities (for the current main revision of the selected project) */
export const fetchingStatusCaptureTreeForMainRevisionSelector: (
  state: RootState
) => CaptureTreeState["fetchingStatus"]["captureTreeForMainRevision"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.captureTree.fetchingStatus.captureTreeForMainRevision;
    }
  );

/** Returns whether capture tree entities have been fetched at least once */
export const hasFetchedCaptureTreeForMainRevisionSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.hasFetched.hasFetchedCaptureTreeForMainRevision;
  }
);

/** Returns all the registration revisions of the current project */
export const allRegistrationRevisionsSelector: (
  state: RootState
) => RegistrationRevision[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return allRegistrationRevisionsAdapter
      .getSelectors()
      .selectAll(state.captureTree.allRegistrationRevisions);
  }
);

/** Returns the fetching status of all the registration revisions of the current project */
export const fetchingStatusAllRegistrationRevisionsSelector: (
  state: RootState
) => CaptureTreeState["fetchingStatus"]["allRegistrationRevisions"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.captureTree.fetchingStatus.allRegistrationRevisions;
    }
  );

/** Returns whether all registration revisions of the current project have been fetched at least once */
export const hasFetchedAllRegistrationRevisionsSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.hasFetched.hasFetchedAllRegistrationRevisions;
  }
);

/**
 * Gets a capture tree entity by providing its ID
 *
 * * @param id ID of the capture tree entity
 */
export function captureTreeEntityByIdSelector(
  id: GUID
): (state: RootState) => CaptureTreeEntity | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!id) {
        return undefined;
      }

      const entities = captureTreeForMainRevisionSelector(state);
      return entities.find((entity) => entity.id === id);
    }
  );
}

/**
 * @returns A string representing the path to the cluster where the capture tree entity is located
 *
 * @param id ID of the capture tree entity
 */
export function captureTreeEntityClusterPathSelector(
  id: GUID
): (state: RootState) => string {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      let entity = captureTreeEntityByIdSelector(id)(state);

      if (!entity) {
        return "";
      }

      const pathArray: string[] = [];

      if (entity.type === CaptureTreeEntityType.cluster) {
        pathArray.unshift(entity.name);
      }

      while (entity && entity.parentId) {
        entity = captureTreeEntityByIdSelector(entity.parentId)(state);

        if (entity && entity.type === CaptureTreeEntityType.cluster) {
          pathArray.unshift(entity.name);
        }
      }

      return pathArray.join("/");
    }
  );
}

/**
 * @returns true iff the capture tree scan entity is processed.
 * To check if a scan entity has been processed we check for the presence of
 * the PointCloudStream ielement as children of the scan ielement.
 *
 * @param id ID of the capture tree scan entity
 */
export function isCaptureTreeScanEntityProcessingSelector(
  id: GUID
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const scanElement = selectIElement(id)(state);

      if (!scanElement) {
        return false;
      }

      const pointCloudStreamElement = selectChildDepthFirst(
        scanElement,
        isIElementPointCloudStream
      )(state);

      return pointCloudStreamElement ? false : true;
    }
  );
}
